<template>
  <v-app>
    <!-- <vue-particles
      color="#240b36"
      :particleOpacity="0.7"
      :particlesNumber="50"
      shapeType="star"
      :particleSize="4"
      linesColor="#c31432"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles> -->
    <v-container class="container">
      <v-card class="grid" width="95%" :elevation="11">
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
          <v-card-text class="text">Car Category</v-card-text>
          <v-select
            v-model="car.category"
            :items="categories"
            required
            solo
            outlined
            chips
            dense
            :rules="globalRules"
          ></v-select>
          <v-card-text class="text">Car Make</v-card-text>
          <v-select
            v-model="car.make"
            :items="makes"
            required
            small-chips
            outlined
            solo
            dense
            :rules="globalRules"
            @change="loadModels"
          ></v-select>
          <v-card-text class="text">Car Model</v-card-text>
          <v-select
            v-model="car.model"
            :items="models"
            required
            small-chips
            outlined
            solo
            dense
            :rules="globalRules"
          ></v-select>
          <v-card-text class="text">Car Year</v-card-text>
          <v-text-field
            v-model="car.year"
            readonly
            required
            solo
            outlined
            chips
            dense
            :rules="globalRules"
          ></v-text-field>
          <v-card-text class="text"
            >Car Color (eg : 'Red' , 'White')</v-card-text
          >
          <v-text-field
            v-model="car.color"
            required
            solo
            outlined
            chips
            dense
            :rules="globalRules"
          ></v-text-field>
          <v-card-text class="text">Car Registration Number</v-card-text>
          <v-text-field
            v-model="car.plateNumber"
            :rules="globalRules"
            required
            solo
            outlined
            chips
            dense
          ></v-text-field>
          <v-card-text class="text">Car Price Per Day</v-card-text>
          <v-text-field
            v-model="car.pricePerDay"
            :rules="globalRules"
            required
            solo
            outlined
            chips
            dense
          ></v-text-field>
          <v-card-text class="text">Car Price Per 3 Days</v-card-text>
          <v-text-field
            v-model="car.pricePer3days"
            :rules="globalRules"
            required
            solo
            outlined
            chips
            dense
          ></v-text-field>
          <v-card-text class="text">Car Price Per Week</v-card-text>
          <v-text-field
            v-model="car.pricePerWeek"
            :rules="globalRules"
            required
            solo
            outlined
            chips
            dense
          ></v-text-field>
          <v-card-text class="text">Car Price Per Month</v-card-text>
          <v-text-field
            v-model="car.pricePerMonth"
            :rules="globalRules"
            required
            solo
            outlined
            chips
            dense
          ></v-text-field>
          <!-- <v-card-text class="text">Update car features</v-card-text>
          <v-text-field
            v-model="car.desc"
            :rules="globalRules"
            label="Description"
            required
            solo
            outlined
            dense
            dark
          ></v-text-field> -->
          <v-card-text class="text">Car Status</v-card-text>
          <v-select
            v-model="car.rentStatus"
            :items="status"
            :rules="globalRules"
            label="Status"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">Update Car Features</v-card-text>
          <v-card-text class="text">CAR TYPE</v-card-text>
          <v-select
            v-model="carFeatures.type"
            :items="type"
            label="Type"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">TRANSMISSION</v-card-text>
          <v-select
            v-model="carFeatures.transmission"
            :items="trans"
            label="Transmission"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">SEATS</v-card-text>
          <v-select
            v-model="carFeatures.seats"
            :items="seats"
            label="Seats"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">FUEL TYPE</v-card-text>
          <v-select
            v-model="carFeatures.petrol"
            :items="fuel"
            label="Fuel"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">GPS</v-card-text>
          <v-select
            v-model="carFeatures.gps"
            type="boolean"
            :items="bool"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">BLUETOOTH</v-card-text>
          <v-select
            v-model="carFeatures.bluetooth"
            :items="bool"
            label="GPS"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">AUX</v-card-text>
          <v-select
            v-model="carFeatures.aux"
            :items="bool"
            label="AUX"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">USB</v-card-text>
          <v-select
            v-model="carFeatures.usb"
            :items="bool"
            label="USB"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">SMOKING</v-card-text>
          <v-select
            v-model="carFeatures.smoking"
            :items="bool"
            label="SMOKING"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-col :cols="12" md="4">
            <v-card-text class="text">Update car images</v-card-text>
            <v-file-input
              style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              prepend-icon="mdi-image-outline"
              accept="image/*"
              @change="uploadImage"
          /></v-col>
          <div>
            <v-row>
              <v-col v-for="url in car.imageUrl" :key="url.id" align="center">
                <v-card
                  class="grid"
                  width="300px"
                  height="300px"
                  style="opacity: 1 !important"
                >
                  <v-row
                    justify="center"
                    align="center"
                    style="height: 61px !important"
                  >
                    <v-btn
                      class="rounded-btn"
                      color="red"
                      @click="removeUrl(url)"
                    >
                      x
                    </v-btn>
                  </v-row>
                  <a :href="url.url" download="car-image.jpg">
                    <v-img
                      :src="url.url"
                      max-height="250px"
                      max-width="250px"
                      contain
                    >
                    </v-img> </a
                ></v-card>
              </v-col>
            </v-row>
          </div>

          <v-row
            align="center"
            style="padding-top: 10x !important"
          >
            <v-col :cols="4" align="center">
              <v-btn :disabled="!valid" dark class="button" @click="update">
                Update
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import DataService from "../services/dataService.js";
const dataService = new DataService();
export default {
  data() {
    return {
      valid: true,
      globalRules: [(v) => !!v || "Field is required"],
      categories: [
        "SuperCar",
        "2 Door Sports Coupe",
        "Luxury Sedan",
        "Local",
        "4WD Drive Vehicles",
        "MPV & Van",
        "Premium",
        "SUV",
      ],
      car: [
        {
          color: "",
          plateNumber: "",
          pricePerDay: "",
          pricePer3days: "",
          pricePerWeek: "",
          pricePerMonth: "",
          desc: "",
          imageUrl: [],
          features: this.carFeatures,
        },
      ],
      carData: [],
      makes: [],
      models: [],
      carFeatures: [],
      status: ["Active", "Inactive"],
      bool: ["true", "false"],
      trans: ["Manual", "Auto"],
      fuel: ["95", "97", "99", "100", "Diesel"],
      seats: ["2", "5", "7", "12"],
      type: ["SEDAN", "HATCHBACK", "MPV", "SUV"],
      lazy: false,
    };
  },

  mounted: function () {
    this.getCar();
  },

  methods: {
    async getCar() {
      if (this.$store.state.user.roles[0] == "admin") {
        this.$setLoader();
        await dataService
          .getCar({ _id: this.$route.params.cardId })
          .then((res) => {
            this.car = res.data.car[0];
            this.models = [res.data.car[0].model];
            this.carData = res.data.carData;
            this.getMakes();
            this.carFeatures = res.data.car[0].features[0];
          });

        this.$disableLoader();
      } else {
        alert("USER NOT AUTHORIZED TO VIEW THIS PAGE");
        this.$router.push({ path: `/` });
      }
    },

    getMakes() {
      this.$setLoader();
      for (let m of this.carData) {
        this.makes.push(m.make);
      }
      this.$disableLoader();
    },

    loadModels() {
      this.$setLoader();
      this.models = [];

      for (let m of this.carData) {
        if (m.make == this.car.make) {
          // this.models.push(m.models);
          for (let c of m.models) {
            this.models.push(c);
          }
        }
      }
      this.$disableLoader();
    },

    uploadImage(file) {
      if (file) {
        let maxId = 0;
        this.$setLoader();
        for (let image of this.car.imageUrl) {
          if (image.id > maxId) {
            maxId = image.id;
          }
        }
        let newId = maxId + 1; // Increment the maximum id for the new image
        file.resource_type = "image";
        file.url = process.env.VUE_APP_CLOUDINARY_URL_IMAGE;
        this.uploadFileToCloudinary(file).then((fileResponse) => {
          // Build the Cloudinary URL with transformation parameters
          const url = `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${fileResponse.public_id}.${fileResponse.format}`;

          this.car.imageUrl.push({ url: url, id: newId });
          this.$disableLoader();
        });
      }
    },

    uploadFileToCloudinary(file) {
      return new Promise(function (resolve, reject) {
        let formData = new FormData();
        formData.append(
          "upload_preset",
          process.env.VUE_APP_CLOUDINARY_PRESET1
        );
        formData.append("resource_type", file.resource_type);
        formData.append("file", file);
        let request = new XMLHttpRequest();
        request.open(
          "POST",
          `https://api.cloudinary.com/v1_1/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/upload`,
          true
        );
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.onreadystatechange = () => {
          if (request.readyState === 4 && request.status === 200) {
            let response = JSON.parse(request.responseText);
            resolve(response);
          }
          if (request.status !== 200) {
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            this.errorText = "error uploading files " + error;
            this.isError = true;
            reject(error);
          }
        };
        request.onerror = (err) => {
          alert("error: " + err);
          reject(err);
        };
        request.send(formData);
      });
    },

    removeUrl(url) {
      const index = this.car.imageUrl.findIndex((item) => item.id === url.id);
      if (index !== -1) {
        this.car.imageUrl.splice(index, 1);
      }
    },

    async update() {
      this.$setLoader();
      await dataService
        .updateCar({ _id: this.$route.params.cardId, car: this.car })
        .then((res) => {
          // this.car = res.data.car[0];
          console.log(res);
          window.location.reload();
        });

      this.$disableLoader();
    },
  },
};
</script>

<style scoped lang="css">
.container {
  /* margin-top: 5%; */
  max-width: none !important;
}

#particles-js {
  background-color: #f9ece0 !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 0 !important;
}
</style>
